<template>
    <div class="container-fluid">
         <b-row>
            <b-col lg="12">
              <p>{{stripeLog}}</p>
            </b-col>
         </b-row>
      </div>
</template>
<script>
import { DataService } from '../../../../config/dataService'
export default {
    name:'BlankPage',
computed: {
    stripeLog(){
        return this.$store.state.thirdPartyPaymentInfo
    },
    paymentId() {
            return this.$store.state.orders.order.extension_attributes.payment_additional_info.find(
        (info) => info.key === 'paymentID',
      );
        },
},
methods: {
        async getStripe(){
        if (this.paymentId) {
          const {
            data: { data, status },
          } = await DataService.post(`bmw/stripe/transaction`, {
            payment_id: this.paymentId.value,
          });

          if (status === 'success') {
            this.$store.commit('setThirdPartyPaymentInfo', data);
            // paymentData.value = data && data['@attributes'];
          } else {
            this.$store.commit('setThirdPartyPaymentInfo', false);
          }
    }
}
    },
    beforeMount() {
        this.getStripe()
        const paymentId = this.$store.state.orders.order.extension_attributes.payment_additional_info.find(
        (info) => info.key === 'paymentID',
      );
      return paymentId
        },
    beforeDestroy () {
        this.$store.commit('setThirdPartyPaymentInfo', '');
    }
}
</script>